<template>
  <b-card>
    <header-details
      @on:cancel-edit="$router.push({name: 'duplicate'})"
      tab="product"
    />
    <b-row>
      <b-col v-if="getSelectedCategory">
        <div class="d-flex">
          <span class="modal-title font-weight-bold mt-1 mr-2">{{ $t('admin.duplicate.park-model') }}:</span>
          <d-text-field :value="getSelectedCategory.club.name" class-name="bg-white" readonly size="sm"/>

          <span class="modal-title font-weight-bold mt-1 mr-2 ml-5">{{ $t('admin.duplicate.impacted-park') }}:</span>
          <d-text-field :value="impactedParks" class-name="bg-white" readonly style="width: 60%" size="sm"/>
        </div>
        <div class="d-flex mt-2">
          <span class="modal-title font-weight-bold mt-1 mr-2">{{ $t('admin.duplicate.used-product') }}:</span>
          <d-text-field
            :value="getSelectedCategory.name"
            class-name="bg-white"
            readonly
            class="cursor-disabled"
            size="sm"
          />
        </div>
      </b-col>
      <b-col v-else>
        <div class="d-flex">
          <span class="modal-title font-weight-bold mr-3 mt-1">{{ $t('admin.duplicate.impacted-park') }}:</span>
          <d-text-field :value="impactedParks" class-name="bg-white" readonly style="width: 60%" size="sm"/>
        </div>
      </b-col>
    </b-row>

    <b-row class="d-flex justify-content-center mt-2">
      <b-col v-if="getSelectedProduct" cols="8">
        <fieldset>
          <legend class="d-flex justify-content-center font-weight-bold h6">
            {{
              getSelectedProduct.club.name
            }}
          </legend>
          <duplicate-product-form
            :product="getSelectedProduct"
            :categories="categories"
          />
          <div class="d-flex justify-content-center">
            <d-button
              :text="$t('admin.duplicate.set-data')"
              class="d-btn-sm d-btn btn d-btn-success font-text-title mt-3"
              @on:button-click="onDuplicateAll"
            />
          </div>
        </fieldset>
      </b-col>
      <b-col cols="12">
        <div style="height: 40px" class="w-100 bg-light-red d-flex justify-content-center align-items-center">
          {{ $t('admin.duplicate.cat-to-edit') }}
        </div>
      </b-col>
    </b-row>
    <b-row class="mt-2">
      <b-col
        v-for="(item, index) of groupedSelectedCategoriesByClub"
        :key="'checked-' + index"
        cols="12"
        class="mt-3"
      >
        <fieldset>
          <legend style="width: auto" class="d-flex font-weight-bold h6">
            <span class="ml-2 mr-2">{{ item.club.name }}</span>
          </legend>
          <duplicate-product-form
           :product="item"
          />
        </fieldset>
      </b-col>

      <b-col cols="12" align="middle">
        <d-button
          :text="$t('admin.duplicate.validate-data')"
          class="d-btn-sm d-btn btn d-btn-success font-text-title mt-3"
          @on:button-click="displayModal"
        />
      </b-col>
      <progress-bar-modal
        modalId="identifier-validate"
        :display="display"
        title="general.actions.notification"
        :is-busy="isBusy"
        :count="count"
        :text="$t('admin.duplicate.ask-for-confirmation')"
        :hide="hide"
        @on:cancel="hide = !hide"
        @on:confirm="onValidate"
      />
    </b-row>
  </b-card>
</template>

<script>
import HeaderDetails from "@views/duplicate/HeaderDetails";
import {isNotUndefinedAndNotNull} from "@/utils/classes";
import {_put} from "@api/services/httpService";
import ProgressBarModal from "@custom/duplicate/ProgressBarModal";
import DuplicateProductForm from "@custom/duplicate/DuplicateProductForm";
import {getAllCategoriesProducts} from "@api/services/products/products.api";

export default {
  data: () => ({
    display: false,
    hide: false,
    categories: [],
    count: {
      success: 0,
      failed: 0,
      totalCount: 0,
    },
    isBusy: false
  }),
  computed: {
    getSelectedProduct() {
      return this.$store.getters['duplicate/getSelectedCategoryModel'];
    },
    getCheckedCategoryList() {
      return this.$store.getters['duplicate/getCheckedCategoryList'];
    },
    impactedParks() {
      let label = '';
      for (let i = 0; i < this.groupedSelectedCategoriesByClub.length; i++) {
        label += i < this.groupedSelectedCategoriesByClub.length - 1 ? this.groupedSelectedCategoriesByClub[i].club.name + ',' : this.groupedSelectedCategoriesByClub[i].club.name;
      }
      return label;
    },
    groupedSelectedCategoriesByClub() {
      let groupList = [];

      for (const item of this.getCheckedCategoryList) {
        if (groupList.length > 0) {
          const checkIfAdded = groupList.find(el => el.club.id === item.club.id);

          if (isNotUndefinedAndNotNull(checkIfAdded)) {
            checkIfAdded.categories.push(item);
          } else {
            groupList.push({club: item.club, categories: [item]});
          }
        } else {
          groupList.push({club: item.club, categories: [item]});
        }
      }


      return groupList.sort((b, a) => a.categories.length - b.categories.length);
    }
  },
  components: {
    DuplicateProductForm,
    ProgressBarModal,
    HeaderDetails
  },
  methods: {
    displayModal() {
      this.count.success = 0;
      this.count.failed = 0;
      this.count.totalCount = this.getCheckedCategoryList.length;
      this.display = !this.display;
    },
    async onValidate() {
      if (this.isBusy === false) {
        this.isBusy = true;
        this.count = {success: 0, failed: 0, totalCount: this.getCheckedCategoryList.length}

        for (let g = 0; g < this.groupedSelectedCategoriesByClub.length; g++) {
          const group = this.groupedSelectedCategoriesByClub[g];

          for (let i = 0; i < group.categories.length; i++) {
            const category = group.categories[i];
            await _put(category['@id'], {name: category.name, description: category.description})
              .then((response) => {
                this.count.success++;
              })
              .catch((err) => {
                this.count.failed++;
              })
              .finally(() => {
                if (this.count.success + this.count.failed === this.count.totalCount) {
                  this.isBusy = false;
                }
              })
            ;
          }
        }
      }
    },
    onDuplicateAll() {
      this.$store.commit('duplicate/updateCategories', {
        field: 'name',
        name: this.getSelectedCategory.name,
        description: this.getSelectedCategory.description
      });
      this.$store.commit('duplicate/updateCategories', {
        field: 'description',
        name: this.getSelectedCategory.name,
        description: this.getSelectedCategory.description
      });
    },
    loadCategories() {
      getAllCategoriesProducts(500)
        .then((response) => {
          this.categories = response.data['hydra:member'];
        })
      ;
    },
  },
  created() {
    this.loadCategories();
  }
}
</script>
<style scoped lang="scss">

.bg-light-red {
  background-color: #FF0101;
  border-radius: 5px;
  color: white;
  font-weight: bold;
}

/deep/ .modal-content {
  border-radius: 8px;
  width: calc(100% - 294px);
  left: 100px;
}

@import "@lazy/_b-card.scss";
</style>
