<template>
  <div>
    <b-row>
      <b-col cols="9">
        <b-row>
          <b-col>
            <label>{{ $t('admin.duplicate.product.type')}}</label>
            <i class="pointer ml-4 mt-1 fa-15x" :class="icon"></i>

            <select v-model="product.type" size="sm" class="form-control">
              <option class="digital">{{$t('admin.duplicate.product.ticket')}}</option>
              <option value="physical">{{$t('admin.duplicate.product.physical')}}</option>
              <option value="walletRefill">{{$t('admin.duplicate.product.wallet-refill')}}</option>
            </select>
          </b-col>
          <b-col>
            <label> {{ $t('admin.duplicate.product.name') }} </label>
            <i class="pointer ml-4 mt-1 fa-15x" :class="icon"></i>

            <d-text-field
              v-model="product.name"
              size="sm"
            />
          </b-col>
          <b-col>
            <label>{{ $t('admin.duplicate.product.availability') }}</label>
            <i class="pointer ml-4 mt-1 fa-15x" :class="icon"></i>

            <select v-model="product.enabled" size="sm" class="form-control">
              <option :value="true">{{ $t('admin.duplicate.product.sale') }}</option>
              <option :sale="false">{{ $t('admin.duplicate.product.non-sale') }}</option>
            </select>
          </b-col>
        </b-row>

        <b-row class="mt-2" >
          <b-col v-if="product.type !== 'physical'">
            <label> {{ labelSelection }} </label>
            <i class="pointer ml-4 mt-1 fa-15x" :class="icon"></i>
            <select size="sm" class="form-control">
              <option :value="null"> {{ $t('general.actions.undefined') }} </option>
            </select>
          </b-col>

          <b-col>
            <label> {{ $t('admin.duplicate.product.price') }} </label>
            <i class="pointer ml-4 mt-1 fa-15x" :class="icon"></i>

            <d-text-field
              size="sm"
              v-model="product.unitaryPrice"
              type="number"
            />
          </b-col>
          <b-col>
            <label> {{ $t('admin.duplicate.product.categories') }} </label>
            <i class="pointer ml-4 mt-1 fa-15x" :class="icon"></i>
            {{ categories }}
            <select
              v-model="product.categories[0]"
              size="sm"
              class="form-control"
            >
              <option
                v-for="(category, index) of categories"
                :key="'category' + index"
                :value="category['@id']"
              >
                {{ category.name}}
              </option>
            </select>
          </b-col>
        </b-row>

      </b-col>
      <b-col cols="4"></b-col>
      <b-col cols="12" class="mt-2">
        <label> {{ $t('admin.duplicate.product.short-description') }}</label>
        <i class="pointer ml-4 mt-1 fa-15x" :class="icon"></i>
        <b-textarea
          v-model="product.shortDescription"
          no-resize
        />
      </b-col>
      <b-col cols="12" class="mt-2">
        <label> {{ $t('admin.duplicate.product.description') }} </label>
        <i class="pointer ml-4 mt-1 fa-15x" :class="icon"></i>

        <email-editor
          :default-content="product.description"
          @on:editor-content:update="product.description = $event"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import EmailEditor from "@custom/EmailEditor";
export default {
  data:()=>({
    productType: null,
  }),
  components: {EmailEditor},
  props: {
    icon: {
      type: String,
      default: 'icofont icofont-download-alt text-danger'
    },
    product: {
      type: Object,
      default: () => {
      }
    },
    categories: {
      type: Array,
      default: () => []
    },
    hasBorder: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    labelSelection() {
      switch (this.product.type) {
        case 'digital':
        return this.$t('admin.duplicate.product.ticket-selection');
        case 'walletRefill':
        return this.$t('admin.duplicate.product.wallet-selection');

      }
    }
  },
  methods: {
    onDuplicate(field) {
      this.$store.commit('duplicate/updateCategories', {field: field, name: this.category.name, description: this.category.description});
    },
  },
  mounted() {
    this.productType = this.product.type;
  }
}
</script>

<style scoped lang="scss">
.fa-15x {
  font-size: 1.3em;
}
</style>
